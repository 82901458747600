import React, { useCallback, useMemo } from "react";
import { WorkflowEntity } from "components/common/entities/WorkflowEntity";
import { WorkflowOption } from "components/ui/selectOptions/WorkflowOption";
import { TitleTooltip } from "components/ui/TitleTooltip";
import { Typography } from "components/ui/Typography";
import { DropdownCellContent } from "components/ui/VirtualTable/components";
import { useStaticOptions } from "hooks/useStaticOptions";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { IntegrationModel } from "models/IntegrationModel";
import { INHERIT, type TInherit } from "utils/ui/select";
import { useStyles } from "./styles";

type TWorkflowProps = {
	defaultValue: string;
	integration: IntegrationModel | null;
	options: (ApprovalAlgorithmModel | TInherit)[];
	value?: TInherit | ApprovalAlgorithmModel | null;
	disabled?: boolean;
	onChange: (value: ApprovalAlgorithmModel | TInherit | null) => void;
};

const getOptionKey = (option: ApprovalAlgorithmModel | TInherit) => (option === INHERIT ? INHERIT : option.id);
const STATIC_OPTIONS: TInherit[] = [INHERIT];
export const WorkflowCell: FC<TWorkflowProps> = ({
	defaultValue,
	integration,
	options: propOptions,
	value,
	disabled,
	onChange
}) => {
	const classes = useStyles();

	const { options, groupBy } = useStaticOptions(propOptions, getOptionKey, STATIC_OPTIONS, false);

	const getApprovalAlgorithmOptionLabel = useCallback(
		(option: ApprovalAlgorithmModel | TInherit) => (option === INHERIT ? defaultValue : option.name),
		[defaultValue]
	);

	const tooltipBody = useMemo(() => {
		return integration && integration.defaultApprovalAlgorithm ? (
			<WorkflowEntity
				withIcon
				className={classes.tooltipBody}
				size="medium"
				approvalAlgorithm={integration.defaultApprovalAlgorithm}
			/>
		) : null;
	}, [classes.tooltipBody, integration]);

	const renderApprovalAlgorithmOptionLabel = useCallback(
		(option: ApprovalAlgorithmModel | TInherit) => {
			if (option === INHERIT) {
				const tooltipHeader = (
					<Typography variant="body_sb" className={classes.tooltipHeader}>
						{defaultValue}
					</Typography>
				);
				return (
					<TitleTooltip header={tooltipHeader} body={tooltipBody}>
						<Typography noWrap variant="body_reg">
							{defaultValue}
						</Typography>
					</TitleTooltip>
				);
			}
			return <WorkflowEntity variant="bold" size="medium" approvalAlgorithm={option} />;
		},
		[defaultValue, tooltipBody, classes.tooltipHeader]
	);

	return (
		<DropdownCellContent
			disabled={disabled}
			getOptionLabel={getApprovalAlgorithmOptionLabel}
			noClear
			onChange={onChange}
			groupBy={groupBy}
			renderOption={WorkflowOption}
			options={options}
			renderLabel={renderApprovalAlgorithmOptionLabel}
			sort={null}
			value={value}
		/>
	);
};
