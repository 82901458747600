import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AccountEntity } from "components/common/entities/AccountEntity";
import { useNewRequestFormContext } from "components/pages/NewRequestPage/newRequestFormContext";
import { Select } from "components/ui/Select";
import { AccountOption } from "components/ui/selectOptions/AccountOption";
import { sortByDisplayName } from "utils/sortUtils";
import type { IntegrationActorModel } from "models/IntegrationActorModel";

const isEqual = (option: IntegrationActorModel, value: IntegrationActorModel) => option.id === value.id;
const getLabel = (option: IntegrationActorModel) => option.displayName;

const TRANSLATION_PREFIX = "common.accountSelectInput";

type TAccountSelectProps = {
	integrationId: string;
};
export const AccountSelect: FC<TAccountSelectProps> = ({ className, innerRef, integrationId }) => {
	const { t } = useTranslation();
	const [query, setQuery] = useState("");

	const {
		state: { fullReceiverUser, receiverIntegrationActors },
		actions: { addReceiverIntegrationActor, removeReceiverIntegrationActor }
	} = useNewRequestFormContext();

	const renderLabel = useCallback(
		(option: IntegrationActorModel) => <AccountEntity withIcon tooltipOnOverflow size="medium" actor={option} />,
		[]
	);

	const handleValueChange = useCallback(
		(actor: IntegrationActorModel | null) => {
			if (!actor) {
				removeReceiverIntegrationActor(integrationId);
			} else {
				addReceiverIntegrationActor(integrationId, actor.id);
			}
			setQuery("");
		},
		[addReceiverIntegrationActor, integrationId, removeReceiverIntegrationActor]
	);

	const options = useMemo(
		() =>
			fullReceiverUser?.integrationActors
				?.filter(integrationActor => integrationActor.integrationId === integrationId)
				.toArray() || [],
		[fullReceiverUser?.integrationActors, integrationId]
	);

	const value = useMemo(() => {
		const selectedIntegrationActorId = receiverIntegrationActors.entrySeq().find(([key]) => key === integrationId)?.[1];
		if (!selectedIntegrationActorId) return null;
		return options.find(option => option.id === selectedIntegrationActorId);
	}, [integrationId, options, receiverIntegrationActors]);

	return (
		<div className={className} ref={innerRef}>
			<Select
				getOptionLabel={getLabel}
				options={options}
				placeholder={t(`${TRANSLATION_PREFIX}.placeholder`)}
				innerRef={innerRef}
				onChange={handleValueChange}
				renderOption={AccountOption}
				isOptionEqualToValue={isEqual}
				inputValue={query}
				renderLabel={renderLabel}
				sort={sortByDisplayName}
				value={value}
			/>
		</div>
	);
};
