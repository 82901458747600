import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useStyles } from "./styles";
import type { TBaseEntityNodeProps } from "components/common/entities/types";

export type TEntityOverrideProps = {
	size: "medium";
	variant?: Exclude<TBaseEntityNodeProps["variant"], "link">;
	inactive?: boolean;
	delayShowTooltip?: number;
};

type TSelectOptionProps<T> = {
	disabled?: boolean;
	isSelected?: boolean;
	isHighlighted?: boolean;
	onSelect: (event: React.SyntheticEvent, value: T) => void;
	readonly?: boolean;
	value: T;
} & (
	| {
			renderOption: (entityProps: TEntityOverrideProps) => React.ReactNode;
			PrefixIcon?: never;
	  }
	| { PrefixIcon?: IconComponent; renderOption?: never }
);

const OPTION_TOOLTIP_DELAY = 500;

export const SelectOption = <T,>({
	children,
	PrefixIcon,
	renderOption,
	className,
	disabled = false,
	isSelected = false,
	isHighlighted = false,
	onSelect,
	readonly = false,
	value
}: TProps<TSelectOptionProps<T>>) => {
	const classes = useStyles();
	const ref = useRef<HTMLDivElement>(null);
	const onClick = useCallback(
		(event: React.MouseEvent) => {
			if (!disabled) {
				onSelect(event, value);
			}
		},
		[disabled, onSelect, value]
	);

	const entityProps: TEntityOverrideProps = useMemo(
		() => ({
			size: "medium",
			variant: isSelected ? "bold" : undefined,
			inactive: disabled,
			delayShowTooltip: OPTION_TOOLTIP_DELAY
		}),
		[isSelected, disabled]
	);

	useEffect(() => {
		if (isHighlighted) {
			ref.current?.scrollIntoView({ block: "nearest", inline: "nearest" });
		}
	}, [isHighlighted]);

	return (
		<div
			ref={ref}
			onClick={onClick}
			className={classNames(
				classes.option,
				{
					[classes.disabled]: disabled,
					[classes.selected]: isSelected,
					[classes.readonly]: readonly,
					[classes.highlighted]: isHighlighted
				},
				className
			)}>
			{PrefixIcon && <PrefixIcon size={20} />}
			{renderOption && renderOption(entityProps)}
			{children}
		</div>
	);
};
