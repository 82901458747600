import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		gap: "var(--spacing-x1, 4px)",
		justifyContent: "flex-start",
		minWidth: "140px"
	},
	top: {
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x1, 4px)",
		justifyContent: "center",
		"&$disabled": {
			color: "var(--color-grey-400)"
		}
	},
	contentContainer: {
		alignItems: "center",
		cursor: "pointer",
		display: "grid",
		gridTemplateColumns: "auto 1fr auto",
		gap: "var(--spacing-x2, 8px)",
		width: "100%",
		"&$noPrefix:not($noSuffix)": {
			gridTemplateColumns: "1fr auto"
		},
		"&$noSuffix:not($noPrefix)": {
			gridTemplateColumns: "auto 1fr"
		},
		"&$noPrefix$noSuffix": {
			gridTemplateColumns: "1fr"
		},
		"&$large": {
			height: "44px"
		},
		"&$medium": {
			height: "32px"
		},
		"&$text": {
			backgroundColor: "var(--color-white)",
			borderColor: "var(--color-grey-500)",
			borderStyle: "solid",
			borderWidth: "1px",
			"&$large, &$medium": {
				padding: "var(--spacing-x1, 4px) var(--spacing-x2, 8px)"
			},
			"&$large": {
				borderRadius: "6px"
			},
			"&&$medium": {
				borderRadius: "5px"
			},
			"&:hover:not($disabled), &$focused:not($disabled), &$active": {
				backgroundColor: "var(--color-purple-50)",
				"&:not($error)": {
					borderColor: "var(--color-black, #272931)"
				}
			},
			"&$disabled": {
				borderColor: "var(--color-grey-400)",
				color: "var(--color-grey-400)"
			},
			"&$error": {
				borderColor: "var(--color-red-700)",
				borderWidth: "2px"
			}
		},
		"&$search": {
			backgroundColor: "var(--color-white)",
			borderColor: "var(--color-grey-500)",
			borderStyle: "solid",
			borderWidth: "1px",
			"&$large, &$medium": {
				padding: "var(--spacing-x1, 4px) var(--spacing-x3, 12px)"
			},
			"&$large": {
				borderRadius: "36px"
			},
			"&&$medium": {
				borderRadius: "33px"
			},
			"&:hover:not($disabled), &$focused:not($disabled), &$active": {
				backgroundColor: "var(--color-purple-50)",
				"&:not($error)": {
					borderColor: "var(--color-black, #272931)"
				}
			},
			"&$disabled": {
				borderColor: "var(--color-grey-400)",
				color: "var(--color-grey-400)"
			},
			"&$error": {
				borderColor: "var(--color-red-700)",
				borderWidth: "2px"
			}
		},
		"&$table": {
			backgroundColor: "transparent",
			"&$disabled": {
				color: "var(--color-grey-400)"
			}
		},
		transitionDuration: "0.2s",
		transitionProperty: "background-color, border-color, border-width",
		transitionTimingFunction: "ease-in-out",
		willChange: "background-color, border-color, border-width"
	},
	prefix: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "var(--spacing-x1, 4px)",
		overflow: "auto"
	},
	suffix: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "var(--spacing-x1, 4px)"
	},
	active: {},
	large: {},
	medium: {},
	text: {},
	search: {},
	table: {},
	focused: {},
	error: {},
	disabled: {},
	hideInput: {},
	noPrefix: {},
	noSuffix: {}
});
