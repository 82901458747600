import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { RoleBar, TRoleBarColumn } from "components/common/RoleBar";
import { RoleBarBundle } from "components/common/RoleBar/components/RoleBarBundle";
import {
	ROLE_BAR_INTEGRATION_WIDTH,
	RoleBarIntegration
} from "components/common/RoleBar/components/RoleBarIntegration";
import { ROLE_BAR_RESOURCE_WIDTH, RoleBarResource } from "components/common/RoleBar/components/RoleBarResource";
import { ROLE_BAR_ROLE_WIDTH, RoleBarRole } from "components/common/RoleBar/components/RoleBarRole";
import { IconButton } from "components/ui/IconButton";
import { ChevronDownIcon } from "components/ui/Icons/ChevronDownIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { useBundles } from "hooks/useBundles";
import { useIntegrations } from "hooks/useIntegrations";
import { useIsOpenState } from "hooks/useIsOpenState";
import { notEmpty } from "utils/comparison";
import { REQUEST_TARGET_CHEVRON_ROLE_WIDTH, useStyles } from "./styles";
import { AccountSelect } from "../AccountSelect";
import type { TBundleRequestTarget, TRequestTarget, TRoleRequestTarget } from "components/pages/NewRequestPage/types";
import type { IntegrationModel } from "models/IntegrationModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";

const EMPTY_COLUMN: TRoleBarColumn = {
	content: <RoleBarBundle />,
	suffixDivider: false
};

const getRoleBarColumns = (
	role: IntegrationResourceRoleModel,
	resource: IntegrationResourceModel,
	integration: IntegrationModel
) => {
	return [
		{
			content: (
				<RoleBarIntegration name={integration.name} imageUrl={integration.imageUrl} isDeleted={integration.isDeleted} />
			),
			width: ROLE_BAR_INTEGRATION_WIDTH
		},
		{
			content: (
				<RoleBarResource
					name={resource.name}
					euid={resource.euid}
					description={resource.description || undefined}
					tags={resource.calculatedTags}
					type={resource.type}
					isDeleted={resource.isDeleted}
				/>
			),
			width: ROLE_BAR_RESOURCE_WIDTH
		},
		{
			content: <RoleBarRole role={role} />,
			width: ROLE_BAR_ROLE_WIDTH,
			suffixDivider: false
		}
	] as TRoleBarColumn[];
};

type TTargetProps = {
	target: TRequestTarget;
	hasBundles?: boolean;
	removeTarget?: (id: string) => void;
	chooseActor?: (id: string) => void;
};

export const RequestTarget: FC<TTargetProps> = ({
	target,
	hasBundles = false,
	removeTarget,
	chooseActor,
	innerRef,
	className
}) => {
	const integrations = useIntegrations();
	const bundles = useBundles();
	const { isOpen: isBundleOpen, toggle } = useIsOpenState();
	const classes = useStyles();
	const isRole = target.type === "role";

	const getRoleRoleBar = useCallback(
		(roleTarget: TRoleRequestTarget): TRoleBarColumn[] => {
			if (!bundles || !integrations) return [];
			const role = roleTarget.fullTarget;
			const resource = role.integrationResource;
			const integration = integrations.get(resource.integrationId);
			if (!integration) return [];
			const roleColumns = getRoleBarColumns(role, resource, integration);
			const emptyChevronColumn: TRoleBarColumn = {
				content: <div className={classes.chevronColumn} />,
				width: REQUEST_TARGET_CHEVRON_ROLE_WIDTH
			};
			return hasBundles ? [EMPTY_COLUMN, ...roleColumns, emptyChevronColumn] : roleColumns;
		},
		[bundles, classes.chevronColumn, hasBundles, integrations]
	);

	const getBundleRoleBar = useCallback(
		(target: TBundleRequestTarget): TRoleBarColumn[] => {
			if (!bundles || !integrations) return [];
			const bundle = target.fullTarget;
			const roles = bundle.bundleItems
				.map(bundleItem => bundleItem.integrationResourceRole)
				.filter(role => role.managed);
			const resources = roles.map(role => role.integrationResource);
			const uniqueResourcesIds = resources
				.map(resource => resource?.id)
				.filter(notEmpty)
				.toSet();
			const integrationIds = resources
				.map(resource => resource?.integrationId)
				.filter(notEmpty)
				.toSet();

			const bundleColumns = [
				{
					content: <RoleBarBundle name={bundle.name} description={bundle.description} tags={bundle.tags} />
				},
				{
					content: <RoleBarIntegration amount={integrationIds.size} />,
					width: ROLE_BAR_INTEGRATION_WIDTH
				},
				{
					content: <RoleBarResource amount={uniqueResourcesIds.size} />,
					width: ROLE_BAR_RESOURCE_WIDTH
				},
				{
					content: <RoleBarRole amount={roles.size} />,
					width: ROLE_BAR_ROLE_WIDTH
				},
				{
					content: <ChevronDownIcon size="large" onClick={toggle} />,
					width: REQUEST_TARGET_CHEVRON_ROLE_WIDTH
				}
			] as TRoleBarColumn[];

			return bundleColumns;
		},
		[bundles, integrations, toggle]
	);

	const columns = useMemo(() => {
		if (isRole) {
			return getRoleRoleBar(target);
		} else {
			return getBundleRoleBar(target);
		}
	}, [getBundleRoleBar, getRoleRoleBar, target, isRole]);

	const onChooseActorClick = useCallback(() => {
		chooseActor?.(target.id);
	}, [chooseActor, target.id]);

	const onRemoveTargetClick = useCallback(() => {
		removeTarget?.(target.id);
	}, [removeTarget, target.id]);

	const bundleItems = useMemo(() => {
		if (isRole) return;
		return target.fullTarget.bundleItems.map(budleItem => {
			const roleTarget = {
				id: budleItem.id,
				fullTarget: budleItem.integrationResourceRole,
				type: "role",
				resourceId: budleItem.integrationResourceRole.integrationResourceId,
				integrationId: budleItem.integrationResourceRole.integrationResource!.integrationId
			} as TRoleRequestTarget;
			return <RequestTarget key={budleItem.id} target={roleTarget} hasBundles />;
		});
	}, [isRole, target.fullTarget]);

	return (
		<div
			className={classNames(classes.targetRowContainer, className, {
				[classes.isBundleOpen]: isBundleOpen
			})}
			ref={innerRef}>
			<div className={classes.targetRow}>
				<div className={classes.roleBarAndAccount}>
					<div className={classNames(classes.roleBarContainer, { [classes.isBundle]: !isRole })}>
						<RoleBar
							selected
							className={classNames({ [classes.chooseActorWrap]: !!chooseActor })}
							noInteraction={!!chooseActor}
							onClick={chooseActor ? onChooseActorClick : undefined}
							columns={columns}
						/>
					</div>
					{isRole && <AccountSelect integrationId={target.integrationId} className={classes.accountSelect} />}
				</div>
				<IconButton
					size="medium"
					className={classNames(classes.closeButton, { [classes.hide]: !removeTarget })}
					onClick={onRemoveTargetClick}>
					<CloseIcon />
				</IconButton>
			</div>
			{isBundleOpen && bundleItems}
		</div>
	);
};
