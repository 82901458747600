import { createUseStyles } from "react-jss";
import { SPACING_X3 } from "globalStylesVariables";

const ACCOUNT_SELECT_WIDTH_PX = 192;
export const REQUEST_TARGET_ACCOUNT_SELECT_OFFSET_WIDTH_PX = ACCOUNT_SELECT_WIDTH_PX + SPACING_X3;
export const REQUEST_TARGET_CHEVRON_ROLE_WIDTH = "20px";

export const useStyles = createUseStyles({
	targetRowContainer: {
		display: "flex",
		flexDirection: "column",
		borderRadius: "8px",
		gap: "var(--spacing-x2, 8px)",
		width: "100%",
		minWidth: "fit-content",
		position: "relative",
		background: "transparent",
		"&$isBundleOpen": {
			padding: "0 0 var(--spacing-x3, 12px) 0",
			// In order to achieve partial background color with border-radius
			"&::before": {
				content: '""',
				position: "absolute",
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				width: "calc(100% - 32px)",
				background: "var(--color-purple-200)",
				borderRadius: "inherit"
			},
			"& $roleBar": {
				backgroundColor: "red"
			}
		}
	},
	targetRow: {
		width: "100%",
		display: "flex",
		zIndex: 0
	},
	roleBarAndAccount: {
		width: "100%",
		display: "flex"
	},
	roleBarContainer: {
		width: `calc(100% - ${REQUEST_TARGET_ACCOUNT_SELECT_OFFSET_WIDTH_PX}px)`,
		display: "flex",
		alignItems: "center",
		alignSelf: "stretch",
		"&$isBundle": {
			marginRight: `${REQUEST_TARGET_ACCOUNT_SELECT_OFFSET_WIDTH_PX}px`
		}
	},
	chooseActorWrap: {
		borderRadius: "10px",
		border: "2px solid var(--color-red-700)"
	},
	bundleTooltipHeader: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x2, 8px)"
	},
	accountSelect: {
		width: `${ACCOUNT_SELECT_WIDTH_PX}px`,
		minWidth: `${ACCOUNT_SELECT_WIDTH_PX}px`,
		marginLeft: "var(--spacing-x3, 12px)"
	},
	closeButton: {
		marginLeft: "var(--spacing-x5, 20px)",
		alignSelf: "center"
	},
	chevronColumn: {
		width: REQUEST_TARGET_CHEVRON_ROLE_WIDTH
	},
	hide: {
		visibility: "hidden",
		opacity: 0
	},
	isBundle: {},
	isBundleOpen: {}
});
