import React, { useCallback, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { Button } from "components/ui/Button";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { Input } from "components/ui/Input";
import { Title } from "components/ui/Title";
import { useApprovalAlgorithmsContext } from "context/approvalAlgorithmsContext";
import { useWorkflowEditorContext } from "context/workflowEditorContext";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useIsOpenState } from "hooks/useIsOpenState";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { removeRedundantSpaces } from "utils/strings";
import { getNameValidators } from "utils/validation/validationUtils";
import { useStyles } from "./styles";

interface IReadonlyProps {
	algorithm: ApprovalAlgorithmModel;
	onEdit?: (algorithmId: string) => void;
	withDelete?: boolean;
	useContext?: never;
}

interface IEditProps {
	useContext: true;
	algorithm?: never;
	onEdit?: never;
	withDelete?: never;
	onDelete?: never;
}

type TProps = IReadonlyProps | IEditProps;

const NameInput: FC<{
	algorithmName: string;
	isValid: boolean;
	onNameChange: (value: string) => void;
}> = ({ algorithmName, innerRef, onNameChange, isValid }) => {
	const inputRef = useRef<HTMLInputElement | null>(null);

	const { t } = useTranslation();

	const onBlur = useCallback(() => {
		if (isValid) {
			onNameChange(removeRedundantSpaces(algorithmName));
		}
	}, [algorithmName, isValid, onNameChange]);

	const classes = useStyles();

	const validators = useMemo(() => getNameValidators(t("shared.name")), [t]);

	return (
		<div ref={innerRef as React.Ref<HTMLDivElement>}>
			<Input
				label={t("common.approvalAlgorithm.label")}
				labelIcon={<WorkflowsIcon size="medium" />}
				autoFocus
				className={classes.nameInput}
				inputRef={inputRef}
				onBlur={onBlur}
				onValueChange={onNameChange}
				type="text"
				validators={validators}
				value={algorithmName || ""}
			/>
		</div>
	);
};

export const AlgorithmTitle: FC<TProps> = ({ useContext, algorithm, onEdit, withDelete }) => {
	return useContext ? (
		<TitleWithContext />
	) : (
		<TitleWithProps algorithm={algorithm} onEdit={onEdit} withDelete={withDelete ?? true} />
	);
};

const TitleWithContext: FC = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const {
		state: { name, isNameValid },
		actions: { addNewRuleFlow, setName }
	} = useWorkflowEditorContext();

	const onNameChange = useCallback(
		(newName: string) => {
			setName(newName);
		},
		[setName]
	);

	const onAddRuleFlow = useCallback(() => addNewRuleFlow(), [addNewRuleFlow]);

	return (
		<Title variant="h3" noBorder className={classes.titleContainer}>
			<div className={classes.title}>
				<NameInput algorithmName={name || ""} isValid={isNameValid} onNameChange={onNameChange} />
			</div>
			<Button variant="secondary" size="medium" onClick={onAddRuleFlow}>
				{t("flow.addRule")}
			</Button>
		</Title>
	);
};

const TitleWithProps: FC<IReadonlyProps> = ({ algorithm, onEdit, withDelete = false }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { isOpen: areYouSureIsOpen, open: openAreYouSureModal, close: closeAreYouSureModal } = useIsOpenState();
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const editWorkflow = useCallback(() => {
		onEdit?.(algorithm.id);
	}, [algorithm, onEdit]);

	const {
		actions: { deleteApprovalAlgorithm }
	} = useApprovalAlgorithmsContext();

	const deleteWithErrorHandle = useCallback(async () => {
		try {
			await deleteApprovalAlgorithm(algorithm.id);
		} catch (error) {
			openGlobalErrorModal(error as Error);
		} finally {
			closeAreYouSureModal();
		}
	}, [algorithm.id, closeAreYouSureModal, deleteApprovalAlgorithm, openGlobalErrorModal]);

	return (
		<>
			{areYouSureIsOpen && (
				<AreYouSureModal
					isOpen
					onClose={closeAreYouSureModal}
					content={t("pages.workflows.deleteWorkflow.modalContent")}
					onAction={deleteWithErrorHandle}
				/>
			)}
			<Title variant="h3" noBorder className={classes.titleContainer}>
				<div className={classes.title}>
					<WorkflowsIcon />
					<span>{algorithm.name}</span>
				</div>
				<div className={classes.actions}>
					{onEdit && (
						<Button size="medium" variant="secondary" onClick={editWorkflow}>
							{t("common.approvalAlgorithm.editWorkflow")}
						</Button>
					)}
					{withDelete && (
						<Button size="medium" onClick={openAreYouSureModal}>
							{t("buttons.delete")}
						</Button>
					)}
				</div>
			</Title>
		</>
	);
};
