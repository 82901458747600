import classNames from "classnames";
import React, { useState, useCallback, useEffect, useMemo, useRef, useLayoutEffect } from "react";
import { useControlled } from "hooks/useControlled";
import { useStyles } from "./styles";
import { Field, type TFieldProps } from "../Field";
import type { IInputState } from "../fieldHelpers/types";

const DELAY_FOCUS_TIMEOUT = 30;
export interface IBaseInputProps extends Omit<TFieldProps<HTMLInputElement>, "renderContent" | "contentRef"> {
	autoFocus?: boolean;
	inputRef?: React.Ref<HTMLInputElement>;
	defaultValue?: string;
	dirty?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onError?: (errors: string[] | null) => void;
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	onMouseDown?: (event: React.MouseEvent<HTMLInputElement>) => void;
	onValueChange?: (value: string) => void;
	onStateChange?: (state: Partial<IInputState>) => void;
	placeholder?: string;
	readonly?: boolean;
	showInput?: boolean;
	type?: "text" | "password" | "email" | "number" | "tel" | "url";
	validators?: ((value: string) => string | null)[];
	touched?: boolean;
	value?: string;
}

export const Input: FC<IBaseInputProps> = ({
	autoFocus = false,
	className,
	defaultValue = "",
	dirty = false,
	disabled,
	error,
	errors: userErrors = null,
	focused,
	description,
	contentContainerClassName,
	id,
	innerRef,
	inputRef: propInputRef,
	isRequired,
	label,
	labelIcon,
	labelInfo,
	onBlur: userOnBlur,
	onChange: userOnChange,
	onError: userOnError,
	onFocus: userOnFocus,
	onKeyDown: userOnKeyDown,
	onKeyUp: userOnKeyUp,
	onMouseDown: userOnMouseDown,
	onStateChange: userOnStateChange,
	onValueChange: userOnValueChange,
	prefix,
	placeholder,
	readonly = false,
	showInput = true,
	type = "text",
	size,
	suffix,
	variant,
	validators = null,
	value: userValue
}) => {
	const [isFocused, setIsFocused] = useControlled({ controlled: focused, default: false });
	const [isTouched, setIsTouched] = useState(false);
	const classes = useStyles();
	const [errorMessages, setErrorMessages] = useState(userErrors ?? undefined);
	const [inputValue, setInputValue] = useControlled<string>({
		controlled: userValue,
		default: defaultValue
	});
	const [isDirty, setIsDirty] = useState(dirty);
	const fallbackRef = useRef<HTMLInputElement>(null);
	const inputRef = propInputRef || fallbackRef;

	useEffect(() => {
		if (userOnStateChange) {
			userOnStateChange({
				dirty: isDirty,
				focused: isFocused,
				touched: isTouched
			});
		}
	}, [isDirty, isFocused, isTouched, userOnStateChange]);

	const validate = useCallback(
		(value: string) => {
			const errors =
				validators?.map(validator => validator(value)).filter((err): err is string => typeof err === "string") || [];
			return errors?.length ? errors : undefined;
		},
		[validators]
	);

	useLayoutEffect(() => {
		if (autoFocus) {
			setTimeout(() => {
				if ("current" in inputRef && inputRef.current) {
					inputRef.current.focus({
						preventScroll: true
					});
				}
			}, DELAY_FOCUS_TIMEOUT);
		}
	}, [autoFocus, inputRef]);

	const onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const newInputValue = event.target.value;
			const errors = validate(newInputValue);
			setIsDirty(true);
			if (errors) {
				setErrorMessages(errors);
				userOnError?.(errors);
			} else {
				setErrorMessages(undefined);
				userOnError?.(null);
			}

			setInputValue(newInputValue);
			if (userOnValueChange) {
				userOnValueChange(newInputValue);
			}
			if (userOnChange) {
				userOnChange(event);
			}
		},
		[validate, setInputValue, userOnValueChange, userOnChange, userOnError]
	);

	const onBlur = useCallback(
		(event: React.FocusEvent<HTMLInputElement>) => {
			setIsFocused(false);
			if (userOnBlur) userOnBlur(event);
		},
		[userOnBlur, setIsFocused]
	);

	const onFocus = useCallback(
		(event?: React.FocusEvent<HTMLInputElement>) => {
			setIsFocused(true);
			setIsTouched(true);
			if (userOnFocus && event) userOnFocus(event);
		},
		[userOnFocus, setIsFocused]
	);

	useEffect(() => {
		setIsDirty(dirty);
	}, [dirty]);

	const fieldProps = useMemo(
		() => ({
			className,
			disabled,
			error,
			errors: errorMessages,
			focused: isFocused,
			description,
			innerRef: innerRef,
			contentContainerClassName: classNames(classes.contentContainer, contentContainerClassName),
			contentRef: inputRef,
			isRequired,
			label,
			labelIcon,
			labelInfo,
			prefix,
			size,
			suffix,
			variant
		}),
		[
			className,
			disabled,
			error,
			errorMessages,
			isFocused,
			description,
			innerRef,
			contentContainerClassName,
			classes.contentContainer,
			inputRef,
			isRequired,
			label,
			labelIcon,
			labelInfo,
			prefix,
			size,
			suffix,
			variant
		]
	);
	return (
		<Field {...fieldProps}>
			<input
				id={id}
				className={classNames(classes.inputHTMLComponent, {
					[classes.hideInput]: !showInput,
					[classes.disabled]: disabled
				})}
				disabled={disabled}
				onBlur={onBlur}
				onChange={onChange}
				onFocus={onFocus}
				onKeyDown={userOnKeyDown}
				onKeyUp={userOnKeyUp}
				onMouseDown={userOnMouseDown}
				placeholder={placeholder}
				readOnly={readonly}
				ref={inputRef}
				required={isRequired}
				spellCheck={false}
				type={type === "password" ? "password" : "text"}
				value={inputValue || ""}
			/>
		</Field>
	);
};
