import { Set } from "immutable";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { editIntegrationResource, type TIntegrationResourceBody } from "api/integrationResources";
import { IntegrationEntity } from "components/common/entities";
import { ResourceEntity } from "components/common/entities/ResourceEntity";
import { UserCard } from "components/common/UserCard";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { MaintainersIcon } from "components/ui/Icons/MaintainersIcon";
import { OwnerIcon } from "components/ui/Icons/OwnerIcon";
import { RequestsIcon } from "components/ui/Icons/RequestsIcon";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { UserAvatarOption } from "components/ui/selectOptions/UserAvatarOption";
import { TitleTooltip } from "components/ui/TitleTooltip";
import { Typography } from "components/ui/Typography";
import { PaginatedVirtualTable, type TTableSelectionProps, type TColumn } from "components/ui/VirtualTable";
import {
	HeaderCellContent,
	TextCellContent,
	ToggleSwitchCellContent,
	UserCellContent,
	UserSelectCellContent
} from "components/ui/VirtualTable/components";
import { useApprovalAlgorithms, useApprovalAlgorithmsList } from "hooks/useApprovalAlgorithms";
import { useIntegrations } from "hooks/useIntegrations";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { INHERIT, type TInherit } from "utils/ui/select";
import { MaintainersCell } from "../MaintainersCell";
import { WorkflowCell } from "../WorkflowCell";
import type { TGetProps } from "components/ui/VirtualTable/types";
import type { IntegrationModel } from "models/IntegrationModel";
import type { IntegrationResourceModel, TIntegrationResourceModel } from "models/IntegrationResourceModel";
import type { UserModel } from "models/UserModel";
import type { Require } from "types/utilTypes";

type TProps = {
	disabled?: boolean;
	fetchIntegrationResources: (page: number) => void | Promise<void>;
	integrationResources: IntegrationResourceModel[];
	onResourceUpdate: (resource: Partial<IntegrationResourceModel> & { id: string }) => void;
	perPage?: number;
	totalIntegrationResources: number;
} & TTableSelectionProps<IntegrationResourceModel>;

const COLUMNS_WIDTHS = [
	"minmax(196px, 340px)",
	"minmax(182px, 280px)",
	"minmax(176px, 1fr)",
	"minmax(168px, 280px)",
	"minmax(200px, 340px)",
	"168px",
	"180px"
] as const;

const DEFAULT_PER_PAGE = 10;

const isFullIntegration = (
	integration?: IntegrationModel | null
): integration is Require<IntegrationModel, "imageUrl" | "application"> => {
	return Boolean(integration?.imageUrl && integration.application);
};

const useIntegrationResourcesTable = ({
	disabled,
	onResourceUpdate
}: Pick<TProps, "disabled" | "onResourceUpdate">) => {
	const integrations = useIntegrations();
	const approvalAlgorithms = useApprovalAlgorithms();
	const approvalAlgorithmList = useApprovalAlgorithmsList();

	const approvalAlgorithmOptions = useMemo<(ApprovalAlgorithmModel | TInherit)[]>(
		() => [INHERIT, ...(approvalAlgorithmList?.toArray() || [])],
		[approvalAlgorithmList]
	);

	const [loadingIntegrationResources, setLoadingIntegrationResources] = useState(Set<string>());

	const onUpdateIntegrationResource = useCallback(
		async (
			resource: Pick<Partial<TIntegrationResourceBody>, "allowsRequests" | "ownerUserId" | "approvalAlgorithmId"> & {
				id: string;
			}
		) => {
			setLoadingIntegrationResources(current => current.add(resource.id));
			await editIntegrationResource(resource);
			setLoadingIntegrationResources(current => current.remove(resource.id));
			const update = { id: resource.id } as Partial<TIntegrationResourceModel> & { id: string };
			if ("allowsRequests" in resource) {
				update.allowsRequests = resource.allowsRequests;
			}
			if ("ownerUserId" in resource) {
				update.ownerId = resource.ownerUserId;
			}
			if ("approvalAlgorithmId" in resource) {
				update.approvalAlgorithmId = resource.approvalAlgorithmId;
			}
			onResourceUpdate(update);
		},
		[onResourceUpdate]
	);

	const onUpdateAllowsRequests = useCallback(
		async (integrationResourceId: string, allowsRequests: boolean) => {
			await onUpdateIntegrationResource({ id: integrationResourceId, allowsRequests });
		},
		[onUpdateIntegrationResource]
	);

	const onUpdateOwner = useCallback(
		async (integrationResourceId: string, newOwner: UserModel | TInherit) => {
			await onUpdateIntegrationResource({
				id: integrationResourceId,
				ownerUserId: newOwner === INHERIT ? null : newOwner.id
			});
		},
		[onUpdateIntegrationResource]
	);

	const onUpdateApprovalAlgorithm = useCallback(
		async (integrationResourceId: string, newApprovalAlgorithm: ApprovalAlgorithmModel | TInherit) => {
			await onUpdateIntegrationResource({
				id: integrationResourceId,
				approvalAlgorithmId: newApprovalAlgorithm === INHERIT ? null : newApprovalAlgorithm.id
			});
		},
		[onUpdateIntegrationResource]
	);

	const getIsDisabled = useCallback(
		(entity: string | IntegrationResourceModel) =>
			disabled || loadingIntegrationResources.has(typeof entity === "string" ? entity : entity.id),
		[disabled, loadingIntegrationResources]
	);

	const getIntegration = useCallback(
		(integrationResource: IntegrationResourceModel) => {
			if (isFullIntegration(integrationResource.integration)) {
				return integrationResource.integration;
			}
			return integrations?.get(integrationResource.integrationId) || null;
		},
		[integrations]
	);

	return {
		approvalAlgorithmOptions,
		approvalAlgorithms,
		getIntegration,
		getIsDisabled,
		onUpdateAllowsRequests,
		onUpdateApprovalAlgorithm,
		onUpdateOwner
	};
};

export const IntegrationResourcesTable: FC<TProps> = ({
	className,
	isLoading,
	disabled = false,
	fetchIntegrationResources,
	innerRef,
	integrationResources,
	onResourceUpdate,
	perPage = DEFAULT_PER_PAGE,
	totalIntegrationResources,
	...selectionProps
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "common.tables.integrationResources" });

	const {
		approvalAlgorithmOptions,
		approvalAlgorithms,
		getIntegration,
		getIsDisabled,
		onUpdateAllowsRequests,
		onUpdateApprovalAlgorithm,
		onUpdateOwner
	} = useIntegrationResourcesTable({ disabled, onResourceUpdate });

	const getOwnerOptionLabel = useCallback(
		(option: UserModel | TInherit) => (option === INHERIT ? t("selectNullValue") : option.fullName),
		[t]
	);
	const getRenderOwnerLabel = useCallback(
		(integrationResource: IntegrationResourceModel) => {
			return function renderOwnerLabel(option: UserModel | TInherit) {
				const integration = getIntegration(integrationResource);
				if (!integration) return null;

				return option === INHERIT ? (
					<TitleTooltip
						header={<Typography variant="body_sb">{t("selectNullValue")}</Typography>}
						body={<UserCard user={integration.ownerId} />}>
						<Typography noWrap relative variant="body_reg">
							{t("selectNullValue")}
						</Typography>
					</TitleTooltip>
				) : (
					<UserCellContent user={option} disabled={getIsDisabled(integrationResource.id)} />
				);
			};
		},
		[getIntegration, getIsDisabled, t]
	);

	const selectedItemsMap = useMemo(
		() => new Map(selectionProps.selectedItems?.map(item => [item.id, item]) || []),
		[selectionProps.selectedItems]
	);

	const columns = useMemo<TColumn<IntegrationResourceModel>[]>(() => {
		const columns: TColumn<IntegrationResourceModel>[] = [
			{
				renderCell: resource => {
					const integration = getIntegration(resource);
					if (!integration) {
						return <TextCellContent text="" disabled={getIsDisabled(resource.id)} />;
					}
					return <IntegrationEntity withIcon noWrap integration={integration} size="medium" />;
				},
				header: <HeaderCellContent text={t("headers.integration")} icon={<IntegrationIcon />} />,
				key: "integration",
				width: COLUMNS_WIDTHS[0]
			},
			{
				renderCell: TextCellContent,
				getProps: integrationResource => {
					return {
						text: integrationResource.type ?? "",
						disabled: getIsDisabled(integrationResource.id)
					};
				},
				header: <HeaderCellContent text={t("headers.resourceType")} />,
				key: "resourceType",
				width: COLUMNS_WIDTHS[1]
			},
			{
				renderCell: resource => <ResourceEntity resource={resource} size="medium" variant="link" />,
				header: <HeaderCellContent text={t("headers.integrationResource")} icon={<ResourcesIcon />} />,
				key: "resource",
				width: COLUMNS_WIDTHS[2]
			},
			{
				renderCell: (integrationResource, options) => {
					const onChange = (newApprovalAlgorithm: ApprovalAlgorithmModel | TInherit | null) => {
						if (newApprovalAlgorithm) {
							void onUpdateApprovalAlgorithm(integrationResource.id, newApprovalAlgorithm);
						}
					};
					return (
						<WorkflowCell
							integration={getIntegration(integrationResource)}
							defaultValue={t("selectNullValue")}
							options={approvalAlgorithmOptions}
							value={
								integrationResource.approvalAlgorithmId
									? approvalAlgorithms?.get(integrationResource.approvalAlgorithmId)
									: INHERIT
							}
							disabled={options.disabled || getIsDisabled(integrationResource.id)}
							onChange={onChange}
						/>
					);
				},
				header: <HeaderCellContent text={t("headers.approvalAlgorithm")} icon={<WorkflowsIcon />} />,
				key: "workflow",
				width: COLUMNS_WIDTHS[3],
				overflow: true
			},
			{
				renderCell: UserSelectCellContent,
				getProps: (integrationResource, options) => ({
					selectedIds: integrationResource.ownerId ? [integrationResource.ownerId] : undefined,
					// value is being used as a fallback when selectedIds passed
					value: !integrationResource.ownerId ? INHERIT : undefined,
					staticOptions: [INHERIT],
					disabled: options.disabled || getIsDisabled(integrationResource.id),
					onChange: (newOwner: UserModel | TInherit | null) => {
						if (newOwner === null) return;
						void onUpdateOwner(integrationResource.id, newOwner);
					},
					getOptionLabel: getOwnerOptionLabel,
					renderLabel: getRenderOwnerLabel(integrationResource),
					noClear: true,
					sort: null,
					renderOption: UserAvatarOption
				}),
				header: <HeaderCellContent text={t("headers.owner")} icon={<OwnerIcon />} />,
				key: "owner",
				width: COLUMNS_WIDTHS[4],
				overflow: true
			} as TColumn<IntegrationResourceModel>,
			{
				renderCell: (integrationResource: IntegrationResourceModel) => (
					<MaintainersCell
						isSelected={selectedItemsMap.has(integrationResource.id) || selectionProps.isAllSelected}
						model={integrationResource}
						getIsDisabled={getIsDisabled}
						tooltipHeader={t("tooltips.integrationResourceMaintainers")}
					/>
				),
				header: <HeaderCellContent text={t("headers.maintainer")} icon={<MaintainersIcon />} />,
				key: "maintainer",
				width: COLUMNS_WIDTHS[5]
			},
			{
				renderCell: ToggleSwitchCellContent,
				getProps: (integrationResource => ({
					disabled: getIsDisabled(integrationResource.id),
					checked: integrationResource.allowsRequests,
					onChange: checked => {
						void onUpdateAllowsRequests(integrationResource.id, checked);
					}
				})) as TGetProps<IntegrationResourceModel, typeof ToggleSwitchCellContent>,
				header: <HeaderCellContent text={t("headers.requestable")} icon={<RequestsIcon />} />,
				key: "requestable",
				width: COLUMNS_WIDTHS[6]
			}
		];
		return columns;
	}, [
		t,
		getIntegration,
		getIsDisabled,
		approvalAlgorithmOptions,
		approvalAlgorithms,
		onUpdateApprovalAlgorithm,
		getOwnerOptionLabel,
		getRenderOwnerLabel,
		onUpdateOwner,
		selectedItemsMap,
		selectionProps.isAllSelected,
		onUpdateAllowsRequests
	]);

	return (
		<PaginatedVirtualTable
			isLoading={isLoading}
			className={className}
			innerRef={innerRef}
			columns={columns}
			rows={integrationResources}
			totalRows={totalIntegrationResources || integrationResources.length}
			perPage={perPage}
			fetchPage={fetchIntegrationResources}
			shouldDisableRow={getIsDisabled}
			{...selectionProps}
		/>
	);
};
