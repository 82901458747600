import React from "react";
import { EntityNode, TEntityNodeColor } from "../EntityNode";

type TProps = {
	actions?: React.ReactNode;
	color?: TEntityNodeColor;
	content: React.ReactNode;
	highlighted?: boolean;
	fixedWidth?: boolean;
	icon?: React.ReactNode;
	isDeleted?: boolean;
	onDelete?: () => void;
	selected?: boolean;
};

const DEFAULT_COLOR = "purple" as TEntityNodeColor;
const BORDER_RADIUS_PX = 32;

export const UserNode: FC<TProps> = props => (
	<EntityNode {...props} borderRadius={BORDER_RADIUS_PX} color={props.color || DEFAULT_COLOR} />
);
