import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	title: {
		fontWeight: "var(--typography-font-weight-bold)",
		display: "flex",
		gap: "1rem",
		alignItems: "center"
	},
	titleContainer: {
		display: "flex",
		margin: 0,
		padding: 0,
		justifyContent: "space-between",
		alignItems: "center",
		minHeight: "2em"
	},
	actions: {
		display: "flex",
		flexDirection: "row",
		gap: "0.5rem"
	},
	nameInput: {
		width: "300px"
	},
	nameInputContainer: {
		"& $nameInput": {
			minWidth: "17.15rem"
		}
	},
	algorithmName: {
		padding: "0.35rem 0.6rem",
		"&:hover": {
			color: "var(--color-grey-dark)",
			fontStyle: "italic",
			border: "1px solid var(--color-grey-light)",
			borderRadius: "var(--border-radius-sharp)"
		}
	}
});
