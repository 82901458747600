import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "hooks/useUser";
import { UserModel } from "models/UserModel";
import { useStyles } from "./styles";
import { NameWithEmail } from "../entities/components/NameWithEmail";
import { UserNode } from "../entities/Nodes/UserNode";
import type { TEntityNodeColor } from "../entities/Nodes/EntityNode";

type TProps = {
	color?: TEntityNodeColor;
	fixedWidth?: boolean;
	isDeleted?: boolean;
	onDelete?: () => void;
	selected?: boolean;
	highlighted?: boolean;
	user: string | UserModel;
};

export const UserCard: FC<TProps> = ({
	className,
	color,
	fixedWidth,
	innerRef,
	isDeleted,
	onDelete,
	highlighted,
	selected,
	user
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const fetchedUser = useUser(typeof user === "string" ? user : "");
	const userModel = useMemo(() => {
		if (!user) return null;
		if (user instanceof UserModel) {
			return user;
		}
		return fetchedUser;
	}, [user, fetchedUser]);

	const deleted = useMemo(() => {
		return isDeleted || userModel?.isDeleted;
	}, [isDeleted, userModel?.isDeleted]);

	const userImageElement = useMemo(() => {
		return userModel?.imageUrl ? <img src={userModel.imageUrl} className={classes.image} /> : null;
	}, [classes.image, userModel?.imageUrl]);

	const userNodeContent = useMemo(() => {
		if (!userModel) return t("common.unknown.user");
		return <NameWithEmail name={userModel.fullName} email={userModel.email} />;
	}, [t, userModel]);
	return (
		<UserNode
			className={className}
			color={color}
			content={userNodeContent}
			fixedWidth={fixedWidth}
			icon={userImageElement}
			innerRef={innerRef}
			isDeleted={deleted}
			onDelete={onDelete}
			highlighted={highlighted}
			selected={selected}
		/>
	);
};
