import { createUseStyles } from "react-jss";
import { ROLE_BAR_HEIGHT_PX } from "components/common/RoleBar/styles";
import { REQUEST_TARGET_ACCOUNT_SELECT_OFFSET_WIDTH_PX } from "./components/RequestTarget/styles";

const ROLE_BAR_AMOUNT = 6;

export const useStyles = createUseStyles({
	titleContainer: {
		display: "flex",
		alignItems: "center",
		gap: "var(--spacing-x3, 12px)"
	},
	titleChipContainer: {
		display: "flex",
		alignItems: "center",
		gap: "var(--spacing-x2, 8px)"
	},
	targets: {
		alignItems: "flex-start",
		display: "flex",
		flex: "1 0 0",
		flexDirection: "column",
		gap: "var(--spacing-x2, 8px)",
		maxHeight: ROLE_BAR_HEIGHT_PX * ROLE_BAR_AMOUNT,
		overflowY: "auto"
	},
	targetsHeader: {
		display: "flex",
		width: "100%"
	},
	rolesTitle: {
		whiteSpace: "nowrap"
	},
	accountsTitle: {
		width: `calc(100% - ${REQUEST_TARGET_ACCOUNT_SELECT_OFFSET_WIDTH_PX}px)`,
		minWidth: "930px",
		textAlign: "right"
	}
});
