import React, { useCallback } from "react";
import { UserSelect, type TUserSelectProps, type TUserSelectOption } from "components/common/UserSelect";
import { UserCellContent } from "./UserCellContent";
import { useVirtualTableContext } from "../../virtualTableContext";
import type { IRowOptions } from "../../types";

type TProps = Omit<TUserSelectProps, "variant"> & IRowOptions;

const UserSelectCellContent: FC<TProps> = props => {
	const {
		state: { compact }
	} = useVirtualTableContext();

	const renderLabel = useCallback(
		(option: TUserSelectOption) =>
			props?.renderLabel ? props.renderLabel(option) : <UserCellContent user={option} disabled={props.disabled} />,
		[props]
	);

	return (
		<UserSelect
			{...props}
			noClear
			renderLabel={renderLabel}
			variant="table"
			size={compact ? "medium" : "large"}
		/>
	);
};

const UserSelectCellContentMemo = React.memo(UserSelectCellContent) as typeof UserSelectCellContent;

export { UserSelectCellContentMemo as UserSelectCellContent };
