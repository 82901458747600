import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import objectHash from "object-hash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Field, TContentProps } from "components/ui/Field";
import { Input } from "components/ui/Input";
import { type ISelectItemListProps, SelectItemList } from "components/ui/SelectItemList";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { Typography } from "components/ui/Typography";
import { defaultValueEquality, useSelect } from "hooks/useSelect";
import { useStopPropagation } from "hooks/useStopPropagation";
import { getLabel, getSuffix, type TOptionRenderer } from "utils/ui/select";
import { useStyles } from "./styles";
import type { TInputSize } from "components/ui/fieldHelpers/types";

type TSelectVariant = "regular" | "table";
export type TTargetValue = { target: { value: string }; [key: string]: unknown };
export type TInputChangeEvent = React.ChangeEvent<HTMLInputElement> | TTargetValue;
export type OptionsListActionPanelProps = {
	position: "top" | "bottom";
	content: JSX.Element;
};
export interface ISelectProps<T> {
	debug?: boolean; // default: false. set to true to make select stay open (development only).
	description?: string; // default: null. description to display under the label
	disabled?: boolean; // default: false. set to true to disable the select.
	noClear?: boolean; // default: false. set to true to hide clear icon.
	errors?: string[]; // default: null. array of error messages.
	filter?: ((options: T[], inputValue: string) => T[]) | null; // default: by label. set the function you want to use to filter options by, use null for no filtering.
	getIconForGroup?: (groupName: string) => IconComponent | undefined;
	getOptionKey?: (option: T) => string; // default: undefined, try get label. how to get key by option.
	getOptionLabel: (option: T) => string; //  how to get label by option. Null will return empty function that returns ""
	getQuery?: (inputValue: string) => string;
	groupBy?: ISelectItemListProps<T>["groupBy"]; // default: null. set the function you want to use to group options by.
	inputValue?: string; // default: undefined. set input value from props (Controlled)
	hideInput?: boolean; // default: false. set to true to hide input.
	hideSelected?: boolean; // default: false. set to true to hide selected value.
	isOptionEqualToValue?: (option: T, value: T) => boolean; // default: ===, equality comparator for options.
	label?: React.ReactNode; // default: "". label for select.
	labelIcon?: React.ReactNode; // default: undefined. icon to display next to label.
	labelInfo?: React.ReactNode; // default: undefined. info to display next to label.
	limit?: number; // default: 30. limit the number of options displayed.
	loading?: boolean; // default: false. show loading indicator.
	onChange?: (value: T | null) => void; // default: undefined. callback on value change.
	onOpenOptions?: () => void; // default: undefined. callback on input focus.
	onInputChange?: (event: TInputChangeEvent) => void; // default: undefined. callback on input value change.
	options: T[]; // required. options to display.
	optionsListClassName?: string; // default: undefined. class name for options list.
	placeholder?: string; // default: "". placeholder for the select.
	inputPlaceholder?: string; // default: "". placeholder for the input.
	renderLabel?: ((option: T) => React.ReactNode) | null; // default: undefined. how to render label (instead of input value).
	renderOption: TOptionRenderer<NoInfer<T>>;
	required?: boolean; // default: false. set to true to make input required.
	shouldDisableOption?: (value: T) => boolean;
	size?: TInputSize; // default large
	sort?: ((options: T[]) => T[]) | null; // default: undefined. set undefined for default sort. set to null if the options need no sort. set to function if options need to be sorted by the function.
	validators?: ((value: string) => string | null)[];
	value?: T | null; // default: undefined. set value from props (Controlled).
	variant?: TSelectVariant; // default: "box". variant of select.
}

function Select<T>(props: TProps<ISelectProps<T>>) {
	const {
		className,
		debug,
		description,
		disabled = false,
		errors: userErrors = null,
		filter,
		getIconForGroup,
		getOptionKey = undefined,
		getOptionLabel: propGetOptionLabel,
		getQuery,
		groupBy,
		noClear = false,
		hideInput,
		hideSelected = false,
		id: propId,
		inputPlaceholder,
		isOptionEqualToValue = defaultValueEquality,
		label = "",
		labelInfo,
		labelIcon,
		limit = 30,
		loading = false,
		onChange: propOnChange,
		onOpenOptions,
		onInputChange: propOnInputChange,
		options: propOptions,
		optionsListClassName,
		placeholder,
		renderLabel: propRenderLabel,
		renderOption,
		required = false,
		shouldDisableOption,
		size = "large",
		sort,
		validators,
		value,
		variant = "regular"
	} = props;

	const [id] = useState(() => propId || uniqueId());
	const selectId = `select-${id}`;
	const inputId = `input-${id}`;

	const {
		clearTriggerRef,
		hasMoreOptions,
		highlightedIndex,
		filteredOptions,
		errorMessages,
		inputValue,
		visible,
		selectFieldRef,
		handleClear,
		handleInputChange,
		getOptionLabel,
		setFilterQuery,
		selectNewValue,
		toggleOpen,
		onSelectItemListKeyDown,
		resetInputValue,
		setTooltipRef,
		getTooltipProps,
		setTriggerRef
	} = useSelect({
		value,
		limit,
		options: propOptions,
		debug,
		onChange: propOnChange,
		getOptionLabel: propGetOptionLabel,
		userErrors,
		onInputChange: propOnInputChange,
		onOpenOptions,
		validators,
		filter: filter,
		getQuery,
		groupBy,
		sort: sort
	});
	const classes = useStyles();

	const resolvedPropGetOptionLabel = useMemo(() => {
		if (propGetOptionLabel === null) return () => "";
		return propGetOptionLabel || getLabel;
	}, [propGetOptionLabel]);

	const onFieldMouseDown = useCallback(
		(event: React.MouseEvent) => {
			if (disabled || clearTriggerRef.current?.contains(event.target as HTMLElement)) {
				return;
			}
			toggleOpen();
		},
		[disabled, toggleOpen, clearTriggerRef]
	);

	const onClear = useStopPropagation(handleClear);

	const suffix = useMemo(
		() =>
			getSuffix({
				clearRef: clearTriggerRef,
				disabled,
				handleClear: onClear,
				open: visible,
				showClear: noClear ? false : Boolean(value),
				suffixClassName: classes.suffix,
				suffixClearClassName: classes.suffixClear
			}),
		[classes.suffix, classes.suffixClear, clearTriggerRef, disabled, onClear, noClear, value, visible]
	);

	const resolvedPropRenderLabel = useMemo(() => {
		if (propRenderLabel === null) return () => "";
		return propRenderLabel ?? getOptionLabel;
	}, [propRenderLabel, getOptionLabel]);

	const valueUniqueIdentifier = useMemo(() => {
		if (value === null || value === undefined) return "";
		if (typeof value === "string") return value;
		if (typeof value === "object" && "id" in value) return value.id;
		return objectHash(value);
	}, [value]);

	useEffect(() => {
		resetInputValue(null, null);
		setFilterQuery("");

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valueUniqueIdentifier]);

	const renderInputContent = useCallback(
		(props: TContentProps<HTMLDivElement>) => {
			const placeholderDiv = (
				<div
					className={classNames(classes.emptyDiv, classes.labelContainer, { [classes.disabled]: disabled })}
					ref={props.ref}>
					<Typography variant="body_reg" noWrap relative>
						{placeholder}
					</Typography>
				</div>
			);
			if (!resolvedPropRenderLabel) return placeholderDiv;
			const renderedLabel = value && !hideSelected ? resolvedPropRenderLabel(value) : null;
			if (!renderedLabel) return placeholderDiv;

			return (
				<div className={classes.labelContainer} ref={props.ref}>
					{typeof renderedLabel === "string" ? (
						<TooltipOnOverflow textVariant="body_reg" content={renderedLabel} />
					) : (
						renderedLabel
					)}
				</div>
			);
		},
		[
			classes.labelContainer,
			resolvedPropRenderLabel,
			value,
			classes.emptyDiv,
			classes.disabled,
			disabled,
			placeholder,
			hideSelected
		]
	);

	const input = useMemo(
		() =>
			hideInput ? null : (
				<Input
					autoFocus
					variant="search"
					id={inputId}
					onChange={handleInputChange}
					onKeyDown={onSelectItemListKeyDown}
					placeholder={value ? undefined : inputPlaceholder}
					validators={validators}
					value={inputValue}
				/>
			),
		[handleInputChange, hideInput, inputId, inputValue, onSelectItemListKeyDown, inputPlaceholder, value, validators]
	);

	const SelectList = useMemo(() => {
		if (!visible || disabled) return null;

		return (
			<SelectItemList
				highlightedIndex={highlightedIndex}
				isSelectedControlled={hideSelected}
				filteredOptions={filteredOptions}
				getIconForGroup={getIconForGroup}
				getTooltipProps={getTooltipProps}
				getOptionKey={getOptionKey}
				getOptionLabel={resolvedPropGetOptionLabel}
				groupBy={groupBy}
				hasMore={hasMoreOptions}
				input={input}
				inputValue={inputValue}
				loading={loading}
				isOptionEqualToValue={isOptionEqualToValue}
				onSelect={selectNewValue}
				optionsListClassName={optionsListClassName}
				renderOption={renderOption}
				shouldDisableOption={shouldDisableOption}
				setTooltipRef={setTooltipRef}
				value={value}
			/>
		);
	}, [
		disabled,
		hasMoreOptions,
		highlightedIndex,
		hideSelected,
		filteredOptions,
		getIconForGroup,
		getOptionKey,
		getTooltipProps,
		groupBy,
		input,
		inputValue,
		isOptionEqualToValue,
		loading,
		optionsListClassName,
		renderOption,
		resolvedPropGetOptionLabel,
		selectNewValue,
		setTooltipRef,
		shouldDisableOption,
		value,
		visible
	]);

	return (
		<div className={classNames(classes.container, className)} id={selectId}>
			<Field
				className={className}
				active={visible}
				disabled={disabled}
				errors={errorMessages || undefined}
				description={description}
				innerRef={setTriggerRef}
				contentRef={selectFieldRef}
				isRequired={required}
				label={label}
				labelInfo={labelInfo}
				labelIcon={labelIcon}
				size={size}
				onFieldMouseDown={onFieldMouseDown}
				renderContent={renderInputContent}
				suffix={suffix}
				variant={variant === "regular" ? "text" : variant}
			/>
			{SelectList}
		</div>
	);
}

const MemoizedComponent = React.memo(Select) as typeof Select;

export { MemoizedComponent as Select };
