import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { InheritOwnerOption } from "components/common/InheritOwnerOption";
import { UserOption } from "components/common/UserOption";
import { Select, type ISelectProps, type TTargetValue } from "components/ui/Select";
import { UserAvatarOption } from "components/ui/selectOptions/UserAvatarOption";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useIntegrations } from "hooks/useIntegrations";
import { useStaticOptions } from "hooks/useStaticOptions";
import { useUser } from "hooks/useUser";
import { useUsersSelect } from "hooks/useUsersSelect";
import { UserModel } from "models/UserModel";
import { INHERIT, type TInherit } from "utils/ui/select";

export type TOwnerOption = UserModel | TInherit;

interface IProps {
	autoAssigned?: boolean;
	integrationId?: string;
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	ownerId: string | TInherit;
	required?: boolean;
	selectInputProps?: Partial<TProps<ISelectProps<TOwnerOption>>>;
	setOwner: (owner: TOwnerOption) => void | Promise<void>;
	showLabel?: boolean;
}

const STATIC_OPTIONS = [INHERIT] as TInherit[];

export const OwnerSelect: FC<IProps> = ({
	autoAssigned,
	integrationId,
	ownerId,
	required = true,
	selectInputProps,
	setOwner,
	showLabel = true
}) => {
	const { t } = useTranslation();
	const [query, setQuery] = useState("");
	const { items: users, isLoading } = useUsersSelect(query, { includeDeleted: false });

	const user = useUser(ownerId !== INHERIT ? ownerId : undefined, false);
	const integrations = useIntegrations();
	const integrationOwnerId = integrations?.get(integrationId || "")?.ownerId;
	const integrationOwner = useUser(integrationOwnerId || "");

	const getFullNameLabel = useCallback(
		(option: TOwnerOption) => (option === INHERIT ? t("shared.integrationDefault") : option.fullName),
		[t]
	);

	const renderLabel = useCallback(
		(option: TOwnerOption) => {
			if (option === INHERIT) {
				if (integrationOwner) {
					return <InheritOwnerOption user={integrationOwner} />;
				}
				return <TooltipOnOverflow content={t("shared.integrationDefault")} textVariant="body_reg" />;
			}
			return <UserOption option={option} noEmail />;
		},
		[integrationOwner, t]
	);

	const getOptionKey = useCallback((option: TOwnerOption) => (option === INHERIT ? INHERIT : option.id), []);

	const enableInherit = Boolean(integrationId);
	const usersArray = useMemo<TOwnerOption[]>(() => users.toArray(), [users]);
	const { options, groupBy } = useStaticOptions(usersArray, getOptionKey, STATIC_OPTIONS, enableInherit);

	const onOwnerChange = useCallback(
		(option: TOwnerOption | null) => {
			if (!users) return;
			void setOwner(option || INHERIT);
		},
		[setOwner, users]
	);

	const onInputChange = useCallback(
		(event: TTargetValue | React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value),
		[]
	);

	return (
		<Select
			{...selectInputProps}
			description={autoAssigned ? t("pages.integrationResource.ownerAutoAssigned") : undefined}
			filter={null}
			getOptionKey={getOptionKey}
			getOptionLabel={getFullNameLabel}
			groupBy={groupBy}
			isOptionEqualToValue={userEquality}
			label={showLabel ? t("pages.integration.owner") : undefined}
			loading={isLoading}
			noClear={!enableInherit}
			onChange={onOwnerChange}
			onInputChange={onInputChange}
			renderOption={UserAvatarOption}
			options={options}
			renderLabel={renderLabel}
			required={required}
			value={user || INHERIT}
		/>
	);
};

const userEquality = (option: TOwnerOption, value: TOwnerOption) => {
	if (option === INHERIT) return value === INHERIT;
	if (value === INHERIT) return false;
	return option.id === value.id;
};
