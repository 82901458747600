import classNames from "classnames";
import _groupBy from "lodash/groupBy";
import uniqueId from "lodash/uniqueId";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { Field, TContentProps } from "components/ui/Field";
import { Input, type IBaseInputProps } from "components/ui/Input";
import { type ISelectItemListProps, SelectItemList } from "components/ui/SelectItemList";
import { Typography } from "components/ui/Typography";
import { useDeepCompareEffect } from "hooks/useDeepCompareEffect";
import { useSelect } from "hooks/useSelect";
import { notEmpty } from "utils/comparison";
import { getOptionKey, getSuffix, type TOptionRenderer } from "utils/ui/select";
import { Chips } from "./components/Chips";
import { useStyles } from "./styles";
import type { THiddenChipType } from "components/ui/chips/HiddenChip";
import type { TInputSize } from "components/ui/fieldHelpers/types";

export type TTargetValue = { target: { value: string } };

export interface IRenderChipParams<T> {
	componentKey: string;
	className?: string;
	noBorder?: boolean;
	onClick?: () => void;
	onRemove?: () => void;
	option: T;
	stretch?: boolean;
}

const arrayEquality = <T,>(newValue: T[] | null | undefined, value: T[] | null | undefined) =>
	Boolean(value?.length === newValue?.length && value?.every((val, i) => val === newValue?.[Number(i)]));

interface IProps<T> {
	chipType?: THiddenChipType;
	chipsLimit?: number; // default: 1. limit the number of chips shown
	chipsPrefix?: IconComponent;
	debug?: boolean; // default: false. set to true to make select stay open (development only).
	description?: string; // default: null. description to display under the label
	disabled?: boolean; // default: false. set to true to disable the select.
	errors?: string[]; // default: null. array of error messages.
	filter?: ((options: T[], inputValue: string) => T[]) | null; // default: by label. set the function you want to use to filter options by, use null for no filtering.
	getIconForGroup?: (groupName: string) => IconComponent | undefined;
	getOptionKey?: (option: T) => string; // default: undefined, try get label. how to get key by option.
	getOptionLabel: (option: T) => string; // how to get label by option. null returns empty string
	getQuery?: (value: string) => string;
	groupBy?: ISelectItemListProps<T>["groupBy"]; // default: null. set the function you want to use to group options by.
	hideInput?: boolean; // default: true. set to true to hide input.
	inputClassName?: string;
	inputPlaceholder?: string; // default: undefined. set input placeholder from props
	isOptionDisabled?: (option: T) => boolean; // default: false, set the function you want to use to disable options.
	isOptionEqualToValue?: (option: T, value: T) => boolean; // default: ===, equality comparator for options.
	label?: React.ReactNode; // default: "". label for select.
	labelIcon?: IconComponent;
	labelInfo?: React.ReactNode;
	limit?: number; // default: 30. limit the number of options displayed.
	limitChipType?: THiddenChipType;
	loading?: boolean; // default: false. show loading indicator.
	onChange?: (value: T[] | null) => void; // default: undefined. callback on value change.
	onFocus?: () => void;
	onInputChange?: (event: (React.ChangeEvent<HTMLInputElement> & TTargetValue) | TTargetValue) => void; // default: undefined. callback on input value change.
	onSelectItem?: (value: T) => void; // default: undefined. callback on item select.
	options: T[]; // required. options to display.
	placeholder?: string; // default: "". placeholder for the select.
	renderChip?: (params: IRenderChipParams<T>) => React.JSX.Element; // default: null. render function for each chip.
	renderOption: TOptionRenderer<NoInfer<T>>;
	required?: boolean; // default: false. set to true to make input required.
	size?: TInputSize; // default large
	sort?: ((options: T[]) => T[]) | null; // default: undefined. set undefined for default sort. set to null if the options need no sort. set to function if options need to be sorted by the function.
	validators?: ((value: string) => string | null)[];
	value?: T[] | null; // default: undefined. set value from props (Controlled).
	variant?: "regular" | "table"; // default: "box". variant of select.
}

function MultipleSelect<T>(props: TProps<IProps<T> & Omit<IBaseInputProps, keyof IProps<T>>>) {
	const {
		chipType,
		chipsLimit = 1,
		chipsPrefix: prefix,
		className,
		debug,
		description = null,
		disabled = false,
		errors = null,
		filter,
		getIconForGroup,
		getOptionKey: propGetOptionKey = getOptionKey,
		getOptionLabel: propGetOptionLabel,
		getQuery = option => option,
		groupBy,
		id: propId,
		inputClassName,
		inputPlaceholder,
		isOptionDisabled: propIsOptionDisabled,
		isOptionEqualToValue = (option, currentValue) => option === currentValue,
		label = "",
		labelIcon,
		labelInfo,
		limit = 30,
		limitChipType,
		loading = false,
		onChange: propOnChange,
		onFocus: propOnFocus,
		onInputChange: propOnInputChange,
		onSelectItem,
		options: propOptions,
		placeholder,
		renderChip: propRenderChip,
		renderOption,
		required = false,
		size = "large",
		sort,
		validators = [],
		value: propValue,
		variant = "regular"
	} = props;

	const { t } = useTranslation();
	const [id] = useState(() => propId || uniqueId());

	const handleOptionSelected = useCallback(
		(value: T[] | null | undefined, newValue: T) => {
			const newValueArray = value?.slice() || [];
			const itemIndex = newValueArray.findIndex(valueItem => isOptionEqualToValue(newValue, valueItem));

			if (itemIndex === -1) {
				newValueArray.push(newValue);
			} else {
				newValueArray.splice(itemIndex, 1);
			}
			return newValueArray;
		},
		[isOptionEqualToValue]
	);

	const {
		hasMoreOptions,
		highlightedIndex,
		filteredOptions,
		errorMessages,
		inputValue,
		open,
		value: controlledValue,
		visible,
		selectFieldRef,
		handleClear,
		handleInputChange,
		getOptionLabel,
		getTooltipProps,
		setFilterQuery,
		selectNewValue,
		setTooltipRef,
		setTriggerRef,
		toggleOpen,
		handleValue,
		onSelectItemListKeyDown,
		resetInputValue
	} = useSelect({
		value: propValue,
		limit,
		options: propOptions,
		debug,
		defaultValue: [],
		equality: arrayEquality,
		handleOptionSelected,
		onChange: propOnChange,
		onFocus: propOnFocus,
		onSelectItem,
		getOptionLabel: propGetOptionLabel,
		userErrors: errors,
		onInputChange: propOnInputChange,
		validators,
		filter,
		getQuery,
		groupBy,
		sort
	});
	const classes = useStyles();

	const onFieldMouseDown = useCallback(
		(ev: React.MouseEvent) => {
			ev.stopPropagation();

			if (disabled) return;

			propOnFocus?.();
			toggleOpen();
		},
		[propOnFocus, disabled, toggleOpen]
	);

	const isOptionDisabled = useCallback(
		(option: T) => {
			if (propIsOptionDisabled) {
				return propIsOptionDisabled(option);
			}
			return false;
		},
		[propIsOptionDisabled]
	);

	const suffixIcon = useMemo(() => {
		return getSuffix({
			disabled,
			open,
			showClear: false,
			suffixClassName: classes.suffix,
			suffixClearClassName: classes.suffixClear
		});
	}, [classes.suffix, classes.suffixClear, disabled, open]);

	const removeChip = useCallback(
		(option: T) => {
			const newValue = controlledValue?.filter(val => !isOptionEqualToValue(val, option)) || [];
			handleValue(newValue);
		},
		[handleValue, isOptionEqualToValue, controlledValue]
	);

	const chips = useMemo(
		() =>
			controlledValue && controlledValue.filter(notEmpty).length ? (
				<Chips
					ChipElement={propRenderChip}
					ChipPrefixIcon={prefix}
					getOptionLabel={getOptionLabel}
					limit={chipsLimit}
					limitChipType={limitChipType}
					onRemove={removeChip}
					selected
					size={size === "large" ? "medium" : "small"}
					type={chipType}
					values={controlledValue || null}
				/>
			) : null,
		[propRenderChip, prefix, getOptionLabel, chipsLimit, limitChipType, removeChip, size, chipType, controlledValue]
	);

	// Using deep compare to block a case of same array values to trigger the hook
	useDeepCompareEffect(() => {
		setFilterQuery("");
		resetInputValue(null);
	}, [controlledValue]);

	const renderInputContent = useCallback(
		(props: TContentProps<HTMLDivElement>) => {
			if (!chips)
				return (
					<div
						className={classNames(classes.labelContainer, classes.emptyDiv, { [classes.disabled]: disabled })}
						ref={props.ref}>
						<Typography variant="body_reg" noWrap>
							{placeholder}
						</Typography>
					</div>
				);

			return (
				<div className={classes.labelContainer} ref={props.ref}>
					{chips}
				</div>
			);
		},
		[classes.labelContainer, classes.disabled, classes.emptyDiv, chips, disabled, placeholder]
	);

	const enableClearAll = controlledValue?.length && !disabled;
	const input = useMemo(
		() => (
			<>
				<Input
					autoFocus
					variant="search"
					id={id}
					className={inputClassName}
					onChange={handleInputChange}
					onKeyDown={onSelectItemListKeyDown}
					placeholder={inputPlaceholder}
					validators={validators}
					value={inputValue}
				/>

				<Button disabled={!enableClearAll} size="small" variant="text" onClick={handleClear}>
					{t("buttons.clearAll")}
				</Button>
			</>
		),
		[
			handleClear,
			handleInputChange,
			id,
			inputValue,
			inputClassName,
			onSelectItemListKeyDown,
			inputPlaceholder,
			validators,
			t,
			enableClearAll
		]
	);

	const onSelect = useCallback(
		(event: React.SyntheticEvent, newValue: T) => {
			selectNewValue(event, newValue, false);
		},
		[selectNewValue]
	);

	const SelectList = useMemo(() => {
		if (!visible || disabled) return null;
		return (
			<SelectItemList
				highlightedIndex={highlightedIndex}
				filteredOptions={filteredOptions}
				getIconForGroup={getIconForGroup}
				getTooltipProps={getTooltipProps}
				getOptionKey={propGetOptionKey}
				getOptionLabel={getOptionLabel}
				groupBy={groupBy}
				loading={loading}
				hasMore={hasMoreOptions}
				inputValue={inputValue}
				isOptionEqualToValue={isOptionEqualToValue}
				onSelect={onSelect}
				renderOption={renderOption}
				shouldDisableOption={isOptionDisabled}
				setTooltipRef={setTooltipRef}
				value={controlledValue}
				input={input}
			/>
		);
	}, [
		disabled,
		hasMoreOptions,
		highlightedIndex,
		filteredOptions,
		input,
		getIconForGroup,
		getOptionLabel,
		getTooltipProps,
		groupBy,
		inputValue,
		isOptionDisabled,
		isOptionEqualToValue,
		loading,
		propGetOptionKey,
		renderOption,
		onSelect,
		setTooltipRef,
		controlledValue,
		visible
	]);

	return (
		<div className={classNames(classes.container, className)}>
			<Field
				disabled={disabled}
				errors={errorMessages || undefined}
				description={description ?? undefined}
				innerRef={setTriggerRef}
				contentRef={selectFieldRef}
				isRequired={required}
				label={label}
				labelIcon={labelIcon}
				labelInfo={labelInfo}
				size={size}
				onFieldMouseDown={onFieldMouseDown}
				renderContent={renderInputContent}
				suffix={suffixIcon}
				variant={variant === "regular" ? "text" : variant}
			/>
			{SelectList}
		</div>
	);
}

const MemoizedComponent = React.memo(MultipleSelect) as typeof MultipleSelect;

export { MemoizedComponent as MultipleSelect };
